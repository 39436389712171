import React from 'react'

import ContentBlock from '../Slices/ContentBlock'
import ImageBlock from '../Slices/ImageBlock'
import Friends from '../Slices/Friends'


// passing in the location prop from Resources.js so we can automatically select tabs on that page based on the url
const SliceZone = ({ sliceZone, location}) => {
    const sliceComponents = {
        content_slice: ContentBlock,
        image_block: ImageBlock,
        friends: Friends
    }

    const pageLocation = location
    //console.log(`location: ${pageLocation}`)

    const sliceZoneContent = sliceZone.map((slice, index, location ) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
          
          return <SliceComponent slice={slice} key={`slice-${index}`} location={pageLocation} />
        }
        return null
      })
    
      return <div>{sliceZoneContent}</div>
    }

export default SliceZone;