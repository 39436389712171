import React from "react";
import {
    Text,
    Grid,
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    Flex,
    Heading,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { RichText } from "prismic-reactjs";
import { ChevronRightIcon } from "@chakra-ui/icons";

const ImageGridItem = styled(Box)`
    position: relative;
`;
const ImageBox = styled.div`
   &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
}
&:hover&:before {
        background: none;
    }

`;
const TextItem = styled(Box)`
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    
    h3 {
       //font-size: 2.25rem;
       font-weight: 400;
       line-height: 2.55rem;
       margin-bottom: .8rem;
    }
    .member-title {
        font-family: 'Open Sans', sans-serif;
        color: #eab117;
        font-weight: 400;
        font-style: italic;
        font-size: 1rem;
        text-transform: uppercase;
        margin-bottom: .125rem;
    }
    .member-org {
        font-family: 'Open Sans', sans-serif;
        color: #eab117;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
    }
`

export const MemberItem = (props) => (
   <ImageBox>
        <TextItem>
            <Text as="h3" fontSize={{ sm: '1.75rem', md: '2rem', lg: '2.25rem'}} color="white">
                {props.memberName}
            </Text>
            <Text className="member-title">
                {props.memberTitle}
            </Text>
            <Text className="member-org" mb="0">
                {props.memberOrg}
            </Text>
            <Text color="white" fontSize="1.25rem" fontWeight="600">
                Learn more<ChevronRightIcon />
            </Text>
        </TextItem>
   </ImageBox>
)



const FriendsModalTrigger = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <ImageGridItem w="100%" h="100%" onClick={onOpen} onClose={onClose} cursor="pointer" key="index">
                
                    <GatsbyImage image={getImage(props.memberImage)} alt={props.memberImageAlt} objectFit="cover" />
                
                
            <MemberItem
                memberName={props.friendName}
                memberTitle={props.memberTitle}
                memberOrg={props.memberOrg}
                memberBio={props.memberBio}
            />
            </ImageGridItem>
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
            <ModalOverlay 
                bg='blackAlpha.700'
                backdropFilter='blur(10px)'/>
            <ModalContent bg="none" boxShadow="none">
                <ModalCloseButton color="white"/>
                <ModalBody p="12">
                    <Heading as="h4" color="white">{props.friendName}</Heading>
                    <Text color="gold.400" fontFamily="Open Sans" fontWeight="600" textTransform="uppercase">{props.memberOrg}</Text>
                    <Text className="modal-text">
                        <RichText render={props.memberBio} />
                    </Text>
                    <Flex w="100%" justifyContent="center" flexWrap="wrap">                        
                        <Box w="50%" p="2">
                            <GatsbyImage image={getImage(props.image1)} alt={props.image1Alt} objectFit="cover" />
                            <Text textAlign="center" color="white">{props.image1Alt}</Text>
                        </Box>
                        <Box w="50%" p="2">
                            <GatsbyImage image={getImage(props.image2)} alt={props.image2Alt} objectFit="cover" />
                            <Text textAlign="center" color="white">{props.image2Alt}</Text>
                        </Box>
                        <Box w="50%" p="2">
                            <GatsbyImage image={getImage(props.image3)} alt={props.image3Alt} objectFit="cover" />
                            <Text textAlign="center" color="white">{props.image3Alt}</Text>
                        </Box>
                        <Box w="50%" p="2">
                            <GatsbyImage image={getImage(props.image4)} alt={props.image4Alt} objectFit="cover" />
                            <Text textAlign="center" color="white">{props.image4Alt}</Text>
                        </Box>
                        <Box w="50%" p="2">
                            <GatsbyImage image={getImage(props.image5)} alt={props.image5Alt} objectFit="cover" />
                            <Text textAlign="center" color="white">{props.image5Alt}</Text>
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
        </>
    )
}

const Friends = ({ slice }) => {
    return (
        <Box>
                <Grid templateColumns={{sm: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }} templateRows="repeat(2, 1fr)" gap={1} mb="4px">
                {slice.primary.friends_of_399.document.data.friend.map((item, index) => {
                    return (
                        <>
                        <FriendsModalTrigger 
                            key={index}
                            friendName={item.friend_name.text}
                            memberTitle={item.friend_title.text}
                            memberOrg={item.friend_organization.text}
                            memberImage={item.friend_image.localFile}
                            memberBio={item.bio.richText}
                            image1={item.image_1.localFile}
                            image1Alt={item.image_1.alt}
                            image2={item.image_2.localFile}
                            image2Alt={item.image_2.alt}
                            image3={item.image_3.localFile}
                            image3Alt={item.image_3.alt}
                            image4={item.image_4.localFile}
                            image4Alt={item.image_4.alt}
                            image5={item.image_5.localFile}
                            image5Alt={item.image_5.alt}
                        />
                    </>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default Friends;



// const FriendsImageGrid = () => {

//     return (
//         <>
//         <Grid templateColumns={{sm: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
//             templateRows="repeat(2, 1fr)" gap={1} mb="4px">
//   <Box w="100%">
//       <ImageGridItem w="100%" h="100%">
//           <StaticImage
//                     className="friend" 
//                     src="../../../images/terri-ray.jpg"
//                     alt="Ray &amp; Terri Thomas"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
//                 <MemberItem 
//             memberName="Ray &amp; Terri Thomas"
//             memberTitle="Campaign Co-founders"
//         />
//             </ImageGridItem>
//             </Box>
//   <Box w="100%" >
//   <ImageGridItem w="100%" h="100%">
//       <StaticImage 
//                     src="../../../images/tim.jpg"
//                     alt="Tim Tenyson"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
//         <MemberItem 
//             memberName="Tim Tennyson"
//             memberTitle="Campaign Co-founder"
//         />
// </ImageGridItem>                
//                 </Box>
//   <Box w="100%" >
//   <ImageGridItem w="100%" h="100%">
//       <StaticImage 
//                     src="../../../images/chambers.jpg"
//                     alt="Terry Chambers"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
//                 <MemberItem 
//                     memberName="Terry Chambers"
//                     memberTitle="Sculptor"
//                 />
//         </ImageGridItem>
//                 </Box>
//   <Box w="100%" >
//   <ImageGridItem w="100%" h="100%">
//       <StaticImage 
//                     src="../../../images/loren.jpg"
//                     alt="Loren Taylor"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
//                 <MemberItem 
//                     memberName="Loren Taylor"
//                     memberTitle={ <><em>Executive Director</em><br /> Wyoming Untrapped</> }
//                 />
//     </ImageGridItem>
//                 </Box>
//   <Box w="100%" >
//   <ImageGridItem w="100%" h="100%">
//       <StaticImage 
//                     src="../../../images/todd.jpg"
//                     alt="Todd Wilkinson"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
    
//             <MemberItem
//                 memberName="Todd Wilkinson"
//                 memberTitle="Author"
//                 />
//                </ImageGridItem> </Box>
//   <Box w="100%" >
//   <ImageGridItem w="100%" h="100%">
//       <StaticImage 
//                     src="../../../images/mangelson.jpg"
//                     alt="Tom Mangelsen"
//                     placeholder="blurred"
//                     layout="fullWidth"
//                 />
//                  <MemberItem
//                 memberName="Tom Mangelsen"
//                 memberTitle="Photographer"
//                 />
//     </ImageGridItem>                
//                 </Box>
// </Grid>
        
//         </>
//     )
// }

// export default FriendsImageGrid;