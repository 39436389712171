import React from "react";
import {
    Container,
    Flex,
    Box,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const ImageBlock = ({ slice }) => {
    return (
        <Container maxW='container.lg'>
            <Flex>
                {slice.items.map((item, index) => {
                    return (
                        <Box w="33.33%" key={index} p="4">
                            <GatsbyImage image={getImage(item.image.localFile)} alt={item.image.alt} />
                        </Box>
                    )
                })}
            </Flex>
        </Container>
    )
}

export default ImageBlock;