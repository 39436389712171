import React, { useState } from "react"
import { Box, Link, Flex, Text, CloseButton, IconButton } from "@chakra-ui/react"
import { HamburgerIcon } from "@chakra-ui/icons"
import { Link as GatsbyLink, useStaticQuery, graphql  } from "gatsby"
import { motion } from "framer-motion"

import styled from "@emotion/styled"
import Bear399Logo from "../StickyHeader/Bear399Logo"

const MobileHeader = styled.header`
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
`

const MotionBox = motion(Box)

const MobileMenu = (props) => {
  const data = useStaticQuery(graphql`
  {
    prismicMainNavigation {
      data {
        navigation_item {
          link_label {
            text
          }
          link_destination {
            url
            target
          }
        }
      }
    }
  }
`)
  const [show, setShow] = useState(false)
  const handleToggle = () => (
      setShow(!show)
  )

  return (
  <Box display={{ base: 'block', md: 'block', lg: 'none' }} position="fixed" top="0" className="mobile-header">
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      paddingLeft=".5rem"
      paddingRight="0.5rem"
      bg="gold.400"
      color="white"
      display="flex"
      {...props}
    >
      
      <Flex bg="gold.400" w="75%">
        <Link
          as={GatsbyLink}
          to="/"
          color="white"
          _hover={{ textDecor: "none" }}
        >
          <MotionBox
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{ duration: 0.5 }}
            p={2}
          >
            
            <Bear399Logo width="200px"/>
          
          </MotionBox>
        </Link>
      </Flex>
    
    <div>
      <Box onClick={handleToggle}>
          { show ? <CloseButton /> : 
         ( <IconButton
            aria-label="Menu"
            icon={<HamburgerIcon />}
            colorScheme="white"
            size="sm"
        />)
  }      </Box>
</div>    
    <Box
        display={{ sm: show ? "block" : "none"}}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        paddingBottom="1rem"
    >
       {data.prismicMainNavigation.data.navigation_item.map((item, index) => {
        return (
          <Text mt={{ base: 4, md: 0 }} display="block" textAlign="center" key={index}>
          <Link to={item.link_destination.url} as={GatsbyLink} onClick={handleToggle} variant="nav-item" activeClassName="mobile-nav-active">
          {item.link_label.text}
        </Link>
        </Text>
        )
      } )}
   </Box>
    </Flex>
    </Box>
  )
}

export default MobileMenu