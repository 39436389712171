import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Box, Text } from '@chakra-ui/react'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '25px' };
  height: auto;
  margin-left: auto;
  margin-right: auto;
  .cls-1 {
        fill: #fff;
      }
`;

const FollowUs = (props) => {
    return (
      <>
  <Link to="https://www.facebook.com/grizzly399project" target="_blank" rel="noopener noreferrer" className="icon-link">
  <Box mb={4}>
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.37942 230.26339" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Icons">
      <path class="cls-1" d="M22.94951,230.26339H69.00219V115.13169H103.158l4.2214-46.05267h-36.612V50.658c0-8.98026,5.98707-11.05266,10.20847-11.05266h25.86622V.15354L71.30473,0C31.85282,0,22.87268,29.397,22.87268,48.27851V69.079H0v46.05267H23.02634C22.94951,167.32475,22.94951,230.26339,22.94951,230.26339Z"/>
    </g>
  </g>
</Logo>
</Box>
<Text 
  textAlign="center"
  fontFamily="Open Sans"
  color="white"
  fontWeight="600"
    >Follow Us</Text>
</Link>
</>
    )
}

export default FollowUs
