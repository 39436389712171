import React from "react";
import {
    Box,
    Heading
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs';
import { CustomLink } from '../../utils/customLink'

const ContentBlock= ({ slice }) => {
    console.log(slice.primary)
    return (

        <Box>
            <Heading as="h2" color="brand.500" fontSize="3.5rem" fontWeight="600" mb="8">
                {slice.primary.block_title.text}
            </Heading>
            {slice.primary.block_image.localFile && (
                <>
                    {slice.primary.align_image === "Right" ? (
                        <Box float="right" w="50%" ml="2rem" mb="1.5rem" pt="0.5rem">
                            <GatsbyImage image={getImage(slice.primary.block_image.localFile)} alt={slice.primary.block_image.alt} />
                        </Box>
                    )
                    : (
                        <Box float="left" w="50%" mr="2rem" mb="1.5rem" pt="0.5rem">
                            <GatsbyImage image={getImage(slice.primary.block_image.localFile)}  alt={slice.primary.block_image.alt} />
                        </Box>
                    )}
                </>
            )}
            
            <RichText render={slice.primary.block_content.richText} serializeHyperlink={CustomLink} />
        </Box>
    );
}

export default ContentBlock;