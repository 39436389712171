import { Box, Heading, Flex, Container, Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import { motion } from "framer-motion"

import HomeNavMenu from "./NavMenu/HomeNavMenu"
import NavMenu from "./NavMenu/NavMenu"
import { FaChevronRight } from "react-icons/fa"
import MobileMenu from "./NavMenu/MobileMenu"

const MotionBox = motion(Box)

const Header = ({ location }) => {
  //console.log(location)

  return (
    <Box as="header" w="100%">
      <MobileMenu />
        <Box
          as="header"
          bg="gold.400"
          p="2"
          display={{ sm: "none", md: "none", lg: "block" }}
          h="2rem"
        >
        
        <Flex
          w="100%"
          justifyContent="flex-end"
          mr="3rem"
          display={{ sm: "none", md: "none", lg: "flex" }}
        >
          <Box mr="4rem">
            <Link to="/donate">
              <Button
                as="h1"
                textAlign="center"
                fontSize="lg"
                bgColor="gold.400"
                color="white"
                borderRadius="0"
                fontFamily="Open Sans"
                _hover={{ backgroundColor: "gold.400", color: "gray.800" }}
                _active={{ backgroundColor: "gold.400", color: "gray.600" }}
              >
                Donate&nbsp;<em>Now</em>&nbsp;
                <FaChevronRight />
              </Button>
            </Link>
          </Box>
        </Flex>
      </Box>
      <Flex>
        <Container
          centerContent
          maxW="100%"
          justifyContent="center"
          h="100%"
          flexWrap="wrap"
        >
          {location == "/" ? <HomeNavMenu /> : <NavMenu />}

          {/* <HomeNavMenu /> */}
        </Container>
      </Flex>
    </Box>
  )
}

export default Header
