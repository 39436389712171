import React from "react"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"
import {
  Box,
  Flex,
  Center,
  Container,
  Text,
  Heading,
  Link,
} from "@chakra-ui/react"
import Bear399Logo from "../StickyHeader/Bear399Logo"
import styled from "@emotion/styled"

const NavLink = styled(Box)`
  border-right: 1px solid white;
  &:last-child {
    border-right: none;
  }
`

const StickyNavMenu = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMainNavigation {
        data {
          navigation_item {
            link_label {
              text
            }
            link_destination {
              url
              target
            }
          }
        }
      }
    }
  `)
  return (
    <>    
      <Flex
        w="100%"
        justifyContent={"space-between"}
        alignItems={"center"}
        className="desktopNav"
        id="sticky-nav-links"
      >
          <Box display={{ sm: 'none', md: 'block'}} w="35%">              
            <Bear399Logo width="300px"/>
          </Box>
          <Flex w="65%">
          {data.prismicMainNavigation.data.navigation_item.map(item => (
           <NavLink
           initial={{
             opacity: 0,
           }}
           animate={{
             opacity: 1,
           }}
           transition={{
             duration: 1,
             delay: 0.75,
           }}
             px="12"
             textAlign="center"
             display="flex"
             flexDirection="column"
             justifyContent="center"
             alignItems="center"
             w="25%"
           >
                <Link
                  as={GatsbyLink}
                  variant="nav-item"
                  _hover={{ textDecoration: "none", color: "black" }}
                  to={item.link_destination.url}
                  activeClassName="sticky-active"
                  target={item.link_destination.target}
                  fontSize={{ lg: '.8rem', xl: '1rem'}}
                >
                {item.link_label.text}
              </Link>
            </NavLink>
        ))}
          </Flex>
      </Flex>
    </>
  )
}

export default StickyNavMenu
