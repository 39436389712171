import React, { useState } from "react"
import { useForm } from "react-hook-form"
import {
  Box,
  FormControl,
  HStack,
  FormLabel,
  Input,
  Button,
  Heading,
  Flex
} from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { VscWarning } from "react-icons/vsc"

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .required()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
})

const errorStyles = {
  color: "#ff6a9e",
  fontSize: "1rem",
  fontFamily: "Open Sans",
}

const SubscribeForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  })

  const [succeeded, setSucceeded] = useState(null)
  const [message, setMessage] = useState("")
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const watchListServObject = watch()
  const [disableSubmit, setDisableSubmit] = useState(false)

  const submitToMailchimp = async (ev) => {
    setDisableSubmit(true);
    setProcessing(true);

    console.log(JSON.stringify(watchListServObject))
    //setsucceeded2(true);

    fetch("/.netlify/functions/mailchimp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(watchListServObject),
    }).then(r => r.json())
    .then(data => {
        console.log(data)
        setProcessing(true);
        if (data.status === 'pending') {
            //setMessage('You have been subscribed. Please check your email to confirm your subscription.')
            setProcessing(false);
            setSucceeded(true);
            reset();
        } else if (data.status === 'subscribed') {
            setMessage(`${data.email} is already subscribed.`)
        } else if (data.status === 'transactional') {
            setMessage(`${data.email} is already subscribed`)
        } else {
            setMessage('Network error. Please try again.')
        }
    })
    .catch(e => {
        setMessage('There was an error subscribing this email address. Please try again.')
    })
    .finally(() => {
        setProcessing(false);
        setDisableSubmit(false);
    })
  }

  const ConfirmMessage = () => (
    <div>
      <h3 style={{ color: "white", textAlign: "center", fontSize: '1.25rem' }}>
        Thank you for subscribing to our newsletter.
        <br />
        Please check your email to confirm your subscription.
      </h3>
    </div>
  )

  return (
    <>
      
      {succeeded ? (
        <ConfirmMessage />
      ) : (
        <>
        <Box>
        <Heading as="h4" color="white" fontSize="1.5rem" textAlign="center">
          Subscribe To Our Newsletter
        </Heading>
      </Box>
        <form
          onSubmit={handleSubmit(submitToMailchimp)}
          id="subscribe-form"
          name="subscribe-form"
        >
            <Flex w="100%" flexWrap="wrap">
                <Box w={{base: '100%', lg: '50%'}} pr={{base: 0, lg: 2}}>
                    <FormControl>
                    <FormLabel style={{ color: "white", fontWeight: 600 }}>First Name</FormLabel>
                    <Input
                        size="sm"
                        variant="dark"
                        className="formField"
                        type="text"
                        name="lastName"
                        {...register("firstName")}
                    />{" "}
                    {errors.firstName && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.firstName?.message}</Box>
                        </HStack>
                        )}
                </FormControl>
                </Box>
                <Box w={{base: '100%', lg: '50%'}} pl={{base: 0, lg: 2}}>
                <FormControl>
            <FormLabel style={{ color: "white", fontWeight: 600  }}>Last Name</FormLabel>
            <Input
                variant="dark"
                size="sm"
              className="formField"
              type="text"
              name="lastName"
              {...register("lastName")}
            />{" "}
            {errors.lastName && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.lastName?.message}</Box>
                  </HStack>
                )}
          </FormControl>
                </Box>
            </Flex>
          
          

          <Box my="4">
              <FormControl>
                <FormLabel style={{ color: "white", fontWeight: 600  }}>Email Address</FormLabel>
                <Input
                variant="dark"
                size="sm"
                  className="formField"
                  type="email"
                  name="email"
                  {...register("email")}
                />{" "}
                {errors.email && (
                      <HStack spacing="5px" style={errorStyles}>
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
              </FormControl>
          </Box>
          <Box>
              {message && (
                  <HStack spacing="5px" style={errorStyles}>
                  <VscWarning /> <Box>{message}</Box>
                </HStack>
              )}
          </Box>

         <Box my="8">
             
              <Button type="submit" className="submitButton" isFullWidth size="sm" bgColor="gold.400" disabled={disableSubmit}>
                <span id="button-text">
                  {succeeded ? "Success!" : processing ? "Submitting..." : "Submit"}
                </span>
              </Button>
         </Box>
        </form>
        </>
      )}
    </>
  )
}

export default SubscribeForm
