import * as React from "react"
import useSticky from "../hooks/useSticky"

import { ChakraProvider } from '@chakra-ui/react'
import './layout.css'
import theme from '../theme'
import MobileMenu from "./NavMenu/MobileMenu"
import Header from "./header"

import Footer from "./Footer/Footer"
import StickyHeader from "./StickyHeader/StickyHeader"

const Layout = (props) => {
  const {location, children} = props;
  const { isSticky, element } = useSticky()

  return (
    <ChakraProvider resetCSS theme={theme}>
    <div className="site">
        <Header location={location} />
        <StickyHeader sticky={isSticky} />
        <main>
            <div ref={element}>{children}</div>
        </main>
        <Footer />
    </div>
</ChakraProvider>
  )
}


export default Layout
