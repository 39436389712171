exports.linkResolver = (doc) => {

    if (doc.type === 'homepage') {
      return `/`
    }

    if (doc.type === 'page') {
      return `/${doc.uid}`
    }

    if (doc.type === 'static_page') {
        return `/${doc.uid}`
    }

    return `/`
}