import React, { useState } from "react"
import { Link as GatsbyLink, useStaticQuery, graphql } from "gatsby"
import {
  Box,
  Flex,
  Center,
  Container,
  Text,
  Heading,
  Link,
} from "@chakra-ui/react"
import { motion } from "framer-motion"
import styled from '@emotion/styled'

import Bear399Logo from "../StickyHeader/Bear399Logo"

const activeStyles = {
  color: "#eab117",
}


const NavLink = styled(Box)`
  border-right: 1px solid white;
  &:last-child {
    border-right: none;
  }
`

const StickyNavMenu = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMainNavigation {
        data {
          navigation_item {
            link_label {
              text
            }
            link_destination {
              url
              target
            }
          }
        }
      }
    }
  `)
  return (
    
      <Flex
        w="100%"
        justifyContent={"space-between"}
        alignItems={"center"}
        className="desktopNav"
        id="nav-links"
        display={{ sm: 'none', md: 'none', lg: 'flex', xl: 'flex'}}
        //marginTop={{ lg: '-2rem', xl: '0'}}
        paddingTop="4rem"
      >
      
          <Box display={{ md: 'none', lg: 'block'}}>              
            <Bear399Logo width="360px"/>
          </Box>
        
        <Flex w="65%">
        {data.prismicMainNavigation.data.navigation_item.map(item => {
          return (
            <>
            {item.link_label.text === "Donate" ?  null :
              <NavLink
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                duration: 1,
                delay: 0.75,
              }}
                px="12"
                textAlign="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                w="25%"
              >
      
                  <Box>
                    <Link
                      as={GatsbyLink}
                      variant="nav-item"
                      _hover={{ textDecoration: "none", color: "#eab117" }}
                      to={item.link_destination.url}
                      activeClassName="active"
                      target={item.link_destination.target}
                      fontSize={{ lg: '.8rem', xl: '1rem'}}
                    >
                    {item.link_label.text}
                  </Link>
                  </Box>
              </NavLink>
            }
            </>
          )
        })}
          </Flex>
      </Flex>
    
  )
}

export default StickyNavMenu
