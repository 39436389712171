import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Container, Box, Text, Heading, Center } from '@chakra-ui/react'

import Seo from "../components/seo"
import SliceZone from "../components/SliceZone/SliceZone"
import { VscTriangleDown } from 'react-icons/vsc'


const PageTemplate = ({ data, location }) => {
  const pageLocation = location.pathname;

  const document = data.prismicPage.data
  const backgroundImage = getImage(document.hero_image.localFile)

return (
  <Layout location={pageLocation}>
    <Seo title="Donate to the Grizzy 399 Project" />
    <Box mt={{ base: '61px', lg: '32px' }} position="relative">
    <GatsbyImage image={backgroundImage} alt={document.hero_image.alt} />
    <Box position="absolute"
            bottom="85"
            z-index="100"
          >
        </Box>
        {document.hero_image.copyright && <Box position="absolute" zIndex="100" bottom="74px">
           <Text textTransform='uppercase'
            p="5px"
            color="white"
            fontWeight="700"
            fontFamily="Open Sans"
            fontSize={{ base: '.5rem', md: '.75rem'}}
            >Photo: {document.hero_image.copyright}</Text>
         </Box>
      }
          <Box position="absolute"
        bottom="0"
        w="100%"
        padding={{sm: '2', md: '4' }}
        backgroundColor="rgba(58, 33, 82, 0.7)"
       >
        
          <Heading as="h1"
            color="white"
            fontSize={{ sm: '2rem', md: '2.5rem', xl: '3.5rem'}}
            textAlign="center"
            fontWeight="600">
            {document.page_title.text}
          </Heading>
        </Box>
    </Box>
    
    <Container maxW='container.lg'>
    <Box textAlign="center" my="8" mb="0">
            <Text fontFamily='Open Sans' fontWeight="600" color="grey.600" mb="0">
              SCROLL
              </Text>
            <Box><Center><VscTriangleDown color="grey.600" size="40" style={{textAlign: 'center'}} /></Center></Box>
          </Box>
      <Box my="12">
        <SliceZone sliceZone={document.body}/>
      </Box>
    </Container>

  </Layout>
)
}

export const query = graphql`
query PageQuery($uid: String!) {
  prismicPage(uid: {eq: $uid}) {
    _previewable
    uid
    data {
      page_title {
        text
      }
      hero_image {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
          }
        }
      }
      body {
        ... on PrismicPageDataBodyImageBlock {
          id
          slice_type
          items {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    transformOptions: {cropFocus: CENTER}
                    quality: 80
                  )
                }
              }
              alt
            }
          }
        }
        ... on PrismicPageDataBodyContentSlice {
          id
          slice_type
          primary {
            block_title {
              text
            }
            block_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    transformOptions: {cropFocus: CENTER}
                    quality: 80
                  )
                }
              }
              alt
            }
            block_content {
              richText
            }
            align_image
          }
        }
      }
    }
  }
}
`

export default PageTemplate
