import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Box, Text } from '@chakra-ui/react'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '80px' };
  height: auto;
  margin-left: auto;
  margin-right:auto;
  .cls-1 {
        fill: #fff;
      }
`;

const WyomingUntrappedLogo = (props) => {
    return (
      <>
  <Link to="/donate" target="_blank" rel="noopener noreferrer" className="icon-link">
  <Box mb={4}>
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.30218 96.87555" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M96.60181,27.72108c-12.88,0-25.01.02-37.14,0-10.97-.02-18.2,7.08-18.24,17.97-.05,12.05-.08,11.95-11.44,16.29-2.32.89-6.92.86-5.68,4.72,1.47,4.56,4.98.96,7.49.33,3.17-.79,6.22-2.05,9.67-3.23,0,1.94.07,3.43-.01,4.92-.22,3.91.97,9.61-1.1,11.32-3.72,3.06-9.33,4.08-14.31,5.25-4.2.98-8.88-2.11-10.59-6.62-5-13.2-9.91-26.43-14.59-39.74a10.22573,10.22573,0,0,1,6.37-13.41q34.335-12.645,68.82-24.89c4.88-1.74,10.14.19,12.24,4.9C91.23181,12.60105,93.67182,19.99107,96.60181,27.72108Zm-61.91,18.73c.07-4.33-3.31-9.54-6.28-9.68-4.54-.21-12.25,4.37-12.34,7.34-.13,4.2,3.3,9.51,6.23,9.63C26.94181,53.94108,34.64182,49.41108,34.69181,46.45106Z"/>
        <path class="cls-1" d="M94.73181,96.84107c-11.83,0-23.65.03-35.48-.01-7.9-.03-11.86-4.08-11.88-12.11q-.045-18.99,0-37.97c.02-8.79,4.1-12.93,12.74-12.93q34.47-.03,68.95-.02c8.18,0,12.21,4.06,12.23,12.27q.03,19.485-.02,38.98c-.02,7.89-4.04,11.81-12.07,11.82q-17.235.015-34.48,0A.03676.03676,0,0,0,94.73181,96.84107Zm-40.15-33.92c0,7.76-.06,15.05.03,22.35.04,2.93,1.53,4.62,4.71,4.53,3.16-.09,6.33-.02,9.49-.02q29.715,0,59.44.01c2.82,0,5.72-.08,5.8-3.79.17-7.6.05-15.2.05-23.08C107.45182,62.92106,81.37183,62.92106,54.58182,62.92106Zm79.02-13.56c1.23-7.16.2-8.42-6.47-8.42q-32.94.015-65.88,0c-6.42.01-7.82,2.09-5.75,8.42Z"/>
        <path class="cls-1" d="M82.51181,74.76106c-5.49,0-11,.17-16.48-.12-1.37-.07-2.66-1.6-3.98-2.45,1.21-.81,2.41-2.3,3.63-2.32q17.475-.285,34.96-.04c1.24.02,2.45,1.47,3.68,2.26-1.28.9-2.52,2.52-3.84,2.58-5.98.28-11.98.12-17.98.12C82.51181,74.79106,82.51181,74.78108,82.51181,74.76106Z"/>
      </g>
    </g>
  </g>
</Logo>
</Box>
<Text 
  textAlign="center"
  fontFamily="Open Sans"
  color="white"
  fontWeight="600"
    >Donate</Text>
</Link>
</>
    )
}

export default WyomingUntrappedLogo
