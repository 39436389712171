import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
    sm: "360px",
    md: "768px",
    lg: "1024px",
    xl: "1440px"
});

const theme = { 
    config: {
        initialColorMode: "light",
        useSystemColorMode: false,
    },
    breakpoints,
    fonts: {
        body: "EB Garamond, serif",
        heading: "EB Garamond, serif"
    },
    colors: {
        gold: {
            100: '#eab117',
            400: '#eab117'
        },
        lime: {
            50: '#D0E067',
            100: '#CADC56',
            200: '#C4D841',
            300: '#B6CB2A',
            400: '#A7BA26',
            500: '#97A923',
            600: '#88981F',
            700: '#79871C',
            800: '#6A7718',
            900: '#5B6615'
        },
        brand: {
            500: '#3a2152'
        },
},
    styles: {
        global: {
            a: {
                color: 'gold.400',
                _hover: {
                    textDecoration: 'none',
                    color: 'gold.400'
                }
            },
            p: {
                color: '#484848',
                fontSize: '1.225rem',
                marginBottom: '1.5rem'
            },
            ul: {
                color: '#484848',
                fontSize: '1.225rem',
                marginBottom: '1.5rem',
                marginLeft: '1.5rem'
            }
        }
    },
    components: {
        Link: {
            baseStyle: {
                color: 'gold.400',
            },
            variants: {
                "nav-item": {
                    color: "white",
                    fontFamily: "Open Sans",
                    fontSize: "1rem",
                    textTransform: "uppercase",
                    fontWeight: 700
                }
            }
        },
        Text: {
            baseStyle: {
                color: '#484848',
                fontSize: '1.225rem',
            },
            variants: {
                'footer-text': {
                    color: 'white',
                    fontSize: '1.125rem',
                    fontFamily: 'Open Sans',
                    fontWeight: 700
                }
        }
    }
}
}

export default extendTheme(theme)