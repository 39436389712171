import React, { createElement, useEffect, useLayoutEffect, useRef } from 'react'
import { Box,
        useDisclosure,
        Button,
        Modal,
        ModalOverlay,
        ModalContent,
        ModalHeader,
        ModalCloseButton,
        ModalBody,
        Link,
        Flex,
        Text,
        Container,
        Heading } from "@chakra-ui/react"

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa"

import ConstantContact from './ConstantContact'

import loadable from '@loadable/component'

import postscribe from 'postscribe'

import MailchimpForm from '../MailchimpForm/MailchimpForm'
import DonateIconFooter from './DonateIconFooter'
import FollowUs from './FollowUs'

function ConstantContactModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    //const constantContact = `className="ctct-inline-form" id="9f88627f-992f-4f26-8a02-eb82996b4167"`
    const constantContact = `id="constantContactFrame" src="/ConstantContact.html" title="Constant Contact" width="100%" height="100%" scrolling="no"`
    
    

    return (
      <>
        <Button onClick={onOpen} isFullWidth size="lg" bgColor="gold.400">Sign Up For Our Newsletter</Button>
  
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader w="85%">Sign Up For Our Newsletter</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Box>
                    <Box my="6">
                        <iframe id="constantContactFrame" src="/ConstantContact.html" title="Constant Contact" width="100%" height="600px" ></iframe>
                    {/* <div className="ctct-inline-form" data-form-id="9f88627f-992f-4f26-8a02-eb82996b4167"></div> */}
                        {/* <div dangerouslySetInnerHTML={{__html: constantContact }} /> */}
                    </Box>
                </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

const Footer = () => {
    // const lglRef = useRef(null);
    
    // useEffect(()=> {
    //   // window is undefined when rendering on server
    //   if (typeof window !== "undefined") {
    //     const embed=`<div class="ctct-inline-form" data-form-id="9f88627f-992f-4f26-8a02-eb82996b4167" />`;
    //     if (lglRef) {

    //       postscribe(
    //         lglRef.current,
    //         embed,
    //       )
    //     }
    // }
    // }, [])
    

    return (
        <Box as="footer" overflowX="hidden">
        <Box w="100%" bg="#323232"  py={15} style={{
        }}>
            <Container
          maxW="container.xl"
          h="auto"
          py={10}>
              {/* <Grid templateColumns={{
            base: '100%',
            md: '1fr 2fr 1fr'
        }}
        alignItems="center"
        justifyContent="space-around"
        gap={4}
        > */}
        <Flex 
            flexWrap="wrap"
            flexDirection="row"
            w="100%"
            justifyContent="space-between"
            height="100%"
            alignItems="center"
        >        
                <Box p="4" justifySelf={{
                    base: 'center',
                    md: 'left'
                }}
                color="white"
                fontFamily="Open Sans"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="2.25rem"
                w={{
                    base: '100%',
                    xl: '25%'
                }}
                mb={{
                    sm: '4rem'
                }}
                textAlign={{
                    base: 'center',
                    xl: 'left',
                }}
                >
                    <Text variant='footer-text'>
                    <FaMapMarkerAlt className="footer-icon" />P.O. Box 747<br />Jackson, WY 83001
                    </Text>
                    {/* <Text variant='footer-text'>
                    <FaPhoneAlt className="footer-icon" /><a href="tel:3307-690-3389" >307-690-3389</a>
                    </Text> */}
                    <Text variant='footer-text'>
                    <FaEnvelope className="footer-icon" /><a href="mailto:info@grizzly399.org">info@grizzly399.org</a>
                    </Text>
                </Box>
                <Box
                p="4"
                w={{
                    base: '100%',
                    xl: '50%'
                }}
                mb={{
                    sm: '4rem'
                }}>
                    {/* <ConstantContactFrame fallback={<div>Loading...</div>} /> */}
                    {/* <ConstantContactModal /> */}
                    {/* <iframe id="constantContactFrame" src="/ConstantContact.html" title="Constant Contact" width="100%" scrolling="no" ></iframe> */}
                    {/* <div ref={lglRef} /> */}
                    <Heading color="white" mb="4" textAlign="center">Sign Up For Our Newsletter</Heading>
                    <ConstantContact />
                    {/* <div class="ctct-inline-form" data-form-id="9f88627f-992f-4f26-8a02-eb82996b4167" /> */}
                    {/* <MailchimpForm /> */}
                        
                    
                </Box>
                <Box
                p="4" w={{
                    base: '100%',
                    xl: '25%'
                }}
                mb={{
                    lg: '0', 
                    xl: '4rem'
                }}>
                    <Flex w="100%" justifyContent="space-around"
                        alignItems="flex-end"
                    >
                        <Box>
                            <DonateIconFooter />
                        </Box>
                        <Box>
                            <FollowUs />
                        </Box>
                    </Flex>
                </Box>
                
              {/* </Grid> */}
              </Flex>
            </Container>
            <Box>
                
            </Box>
        </Box>
        <Box w="100%" bg="#323232">
        <Container maxW="container.lg" py="4" w="100%">
        <Flex justifyContent="space-between" fontSize={[ "sm", "md" ]} color="white" fontFamily="Open Sans" fontWeight="600" flexWrap="wrap" textAlign="center">
        <Box w={{ sm: '100%', md: '33%'}}>
            &copy; Deidre Bainbridge Wildlife Fund, {new Date().getFullYear()}
        </Box>
        <Box  w={{ sm: '100%', md: '33%'}}>
            <Link to="/">Terms Of Use</Link>
        </Box>
        <Box  w={{ sm: '100%', md: '33%'}}>
            <Link to="/">Privacy Policy</Link>
        </Box>
    </Flex>
    </Container>
    </Box>
    </Box>
    )
}

export default Footer